import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'
import { Bolder, Header2 } from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import {
  Klienci,
  Konsultacje,
  Przedszkole,
  Spacery,
  Szkolenia,
} from '@/components/Gallery/gallery.constants'
import Gallery from '@/components/Gallery/Gallery'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;
  padding: 30px 0;

  img {
    object-fit: contain;
    image-orientation: unset;
  }
`

const Description = styled.div`
  padding: 50px 0 0;

  svg {
    margin: 30px 0;
  }

  @media (max-width: 991px) {
    padding: 0;
  }
`

const Head = () => (
  <>
    <title>
      Pet School - Szkolenia i terapia behawioralna zwierząt | Galeria
    </title>
  </>
)

const GalleryPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Galeria</Bolder>
              </Header2>
            </Description>

            <Description>
              <Header2 marginBottom={39}>Szkolenia indywidualne</Header2>
            </Description>

            <Gallery items={Klienci} />

            <Description>
              <Header2 marginBottom={39}>Konsultacje</Header2>
            </Description>

            <Gallery items={Konsultacje} />

            <Description>
              <Header2 marginBottom={39}>Psie przedszkole</Header2>
            </Description>

            <Gallery items={Przedszkole} />

            <Description>
              <Header2 marginBottom={39}>Specery socjalizacyjne</Header2>
            </Description>

            <Gallery items={Spacery} />

            <Description>
              <Header2 marginBottom={39}>Szkolenia psów</Header2>
            </Description>

            <Gallery items={Szkolenia} />
          </Container>
        </Wrapper>
      </Layout>
    </>
  )
}

export default GalleryPage
